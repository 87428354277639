<template lang="pug">
div
  v-container(fluid)
    DataFilter(:filterProps="filters", :items="dataset", :paginate="false")
      template(v-slot:actions)
        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2.mt-1(
              v-on="on",
              color="primary",
              fab,
              outlined,
              small,
              @click="getData"
            )
              v-icon mdi mdi-refresh
          span Atualizar

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2.mt-1(
              v-on="on",
              v-if="isDeveloper",
              color="primary",
              fab,
              outlined,
              small,
              @click="importToProduction",
              @click.right.prevent="enviarComnado"
            )
              v-icon mdi mdi-arrange-send-to-back
          span Importar modelo

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2.mt-1(
              v-on="on",
              v-if="isDeveloper",
              color="primary",
              fab,
              outlined,
              small,
              to="/datahub/cadastro/0"
            )
              v-icon mdi mdi-plus
          span Adicionar

      template(v-slot:default="{ items }")
        v-data-table.registrosTable(
          :sort-by="['id']",
          :sort-desc="[true]",
          dense,
          :footer-props="footerProps",
          :itens-per-page="50",
          item-key="id",
          :headers="dataHeaders",
          :items="items",
          :single-expand="true",
          show-expand
        )
          template(v-slot:item.execucao_unica="{ item }")
            v-icon(:color="item.execucao_unica ? 'green' : 'red'") {{ item.execucao_unica ? "fa-check" : "fa-close" }}

          template(v-slot:item.tipo_intervalo="{ item }")
            span(v-if="!item.execucao_unica") {{ item.intervalo }} {{ getIntervalName(item.tipo_intervalo) }}

          template(v-slot:item.datahora_inicio="{ item }")
            span {{ new Date(item.datahora_inicio).toLocaleString() }}

          template(v-slot:item.datahora_fim="{ item }")
            span {{ new Date(item.datahora_fim).toLocaleString() }}

          template(v-slot:item.actions="{ item }")
            td.text-center.pr-2.pl-0
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="getLogs(item)"
                  )
                    v-icon mdi mdi-information-outline
                span Logs do item

              v-tooltip(bottom, v-if="item.status !== 'PENDENTE'")
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="linkVisualizacao(item.id, item.nome)"
                  )
                    v-icon mdi mdi-eye-circle-outline
                span Visualizar

              v-tooltip(bottom, v-else="item.status === 'PENDENTE'")
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="linkCadastro(item.id, item.nome)",
                    :disabled="item.status !== 'PENDENTE'"
                  )
                    v-icon mdi mdi-circle-edit-outline
                span Editar

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    :disabled="item.status != 'PENDENTE'",
                    fab,
                    small,
                    icon,
                    @click="datasetItem = item; exibirDialogExclusao = true"
                  )
                    v-icon mdi mdi-close-circle-outline
                span Excluir

              v-tooltip(bottom, v-if="item.status === 'PENDENTE'")
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="sendToProcess(item.id)",
                    :disabled="item.status !== 'PENDENTE'"
                  )
                    v-icon mdi mdi-play-circle-outline
                span Enviar para processamento

              v-tooltip(bottom, v-if="item.status != 'PENDENTE'")
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="sendToStop(item.id)",
                    :disabled="item.status !== 'EXECUTANDO'"
                  )
                    v-icon mdi mdi-stop-circle-outline
                span Finalizar processamento

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-if="isDeveloper",
                    v-on="on",
                    color="primary",
                    :disabled="item.status === 'PENDENTE'",
                    fab,
                    small,
                    icon,
                    @click="datasetItem = item; exibirDialogClonar = true",
                    @click.right.prevent="cloneToProduction(item)"
                  )
                    v-icon mdi mdi-text-box-multiple-outline
                span Clonar

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="linkResultado(item.id, item.nome)",
                    :disabled="item.status === 'PENDENTE'"
                  )
                    v-icon mdi mdi-chart-box-multiple-outline
                span Visualizar resultados

          template(
            v-slot:item.data-table-expand="{ item, expand, isExpanded }"
          )
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-btn(
                  v-on="on",
                  color="primary",
                  fab,
                  small,
                  icon,
                  :disabled="item.status === 'PENDENTE'",
                  @click="getClientesLogs(!isExpanded ? item : {}); expand(!isExpanded)"
                )
                  v-icon mdi mdi-math-log
              span Logs de execução

          template(v-slot:expanded-item="{ item, isExpanded }")
            td.ma-2.pa-2(
              v-if="datasetClientesLogs.length === 0",
              colspan="123",
              style="background: #efefef"
            )
              v-progress-linear(color="primary", indeterminate)
            td.ma-2.pa-2(v-else, colspan="123", style="background: #efefef")
              DataFilter(
                :filterProps="filtersClientes",
                :items="datasetClientesLogs",
                :paginate="false"
              )
                template(v-slot:actions)
                  .ma-2.mb-0.pa-2.justify-end.d-flex(style="background: #fff")
                    div(style="width: 200px")
                      b Selecionados:
                      span {{ logsSeleccionados }} Cliente(s)
                    div(style="width: 150px")
                      b Sucesso:
                      span {{ logsSucesso }} Cliente(s)

                template(v-slot:default="{ items }")
                  v-data-table.ma-2.mt-0.pa-0.clientesLogsTable(
                    :headers="clientesLogsHeaders",
                    :items="items",
                    dense,
                    disable-pagination,
                    hide-default-footer,
                    :loading="clientesisLoading",
                    :single-expand="true",
                    show-expand,
                    style="border: 1px solid #efefef"
                  )
                    template(v-slot:item.statusserver="{ item }")
                      v-tooltip(bottom)
                        template(v-slot:activator="{ on, attrs }")
                          v-chip(
                            v-on="on",
                            v-bind="attrs",
                            :color="item.statusserver ? 'success' : 'error'",
                            small
                          )
                        span {{ item.statusserver ? "Conectado" : "Desconectado" }}

                    template(v-slot:item.actions="{ item }")
                      td.text-center.pr-2.pl-0
                        v-menu(
                          v-model="item.menu",
                          offset-y,
                          :close-on-content-click="false"
                        )
                          template(v-slot:activator="{ on, attrs }")
                            v-btn(
                              v-if="isDeveloper",
                              color="primary",
                              v-bind="attrs",
                              v-on="on",
                              fab,
                              small,
                              icon,
                              :disabled="item.mensagem.search('Tentativas restantes: 0') == -1 ? (datasetItem.execucao_unica == 1 ? true : false) : false"
                            )
                              v-icon mdi mdi-refresh

                          v-card(style="width: 200px")
                            v-card-title.mb-0.pb-0(style="font-size: 16px") Tentativas
                            v-card-text.d-flex
                              v-text-field(
                                v-model="tentativasComandos",
                                dense,
                                outlined,
                                hide-details,
                                type="number",
                                min="1",
                                max="3",
                                reverse
                              )
                              v-btn.ml-2.mt-0(
                                color="primary",
                                fab,
                                small,
                                icon,
                                @click="getReenviarComando(item)"
                              )
                                v-icon mdi mdi-send

                    template(v-slot:expanded-item="{ item, isExpanded }")
                      td.pa-2.pl-8.pr-0(
                        colspan="123",
                        style="background: #efefef"
                      )
                        v-data-table.clientLogsTable(
                          :headers="clientesLogsInfoHeaders",
                          :items="item.logs",
                          dense,
                          :footer-props="footerPropsLogs",
                          style="border: 1px solid #efefef"
                        )
                          template(v-slot:item.datahora="{ item }")
                            span {{ new Date(item.datahora).toLocaleString() }}

  v-dialog(v-model="exibirLogs", max-width="900")
    v-card
      v-card-text
        v-container(fluid)
          v-row(no-gutters)
            v-col.ma-2.ml-0.pl-0.d-flex.mb-6(cols="12", sm="12", md="12")
              div(style="width: 155px")
                h3 Logs
            v-col(cols="12", sm="2", md="2")
              b ID
            v-col(cols="12", sm="10", md="10")
              span {{ fullItem.id }}
            v-col(cols="12", sm="2", md="2")
              b Nome
            v-col(cols="12", sm="10", md="10")
              span {{ fullItem.nome }}
            v-col(cols="12", sm="2", md="2")
              b Descrição
            v-col(cols="12", sm="10", md="10")
              span {{ fullItem.descricao }}
            v-col.mt-4(cols="12", sm="2", md="2")
              b Tipo Execução
            v-col.mt-4(cols="12", sm="10", md="10")
              span(color="primary", small, v-if="fullItem.execucao_unica") Única
              span(color="primary", small, v-else) à cada {{ fullItem.intervalo }} {{ getIntervalName(fullItem.tipo_intervalo) }}
            v-col.mt-1(cols="12", sm="2", md="2")
              b Status
            v-col.mt-1(cols="12", sm="10", md="10")
              v-chip(
                color="grey",
                small,
                v-if="fullItem.status === 'PENDENTE'"
              ) Pendente
              v-chip(
                color="primary",
                small,
                v-else-if="fullItem.status === 'EXECUTANDO'"
              ) Executando
              v-chip(
                color="error",
                small,
                v-else-if="fullItem.status === 'ERRO'"
              ) ERRO
              v-chip(color="success", small, v-else) Finalizado

        v-divider.ma-0.mt-4.pa-0(
          style="margin-left: 0 !important; margin-right: 0 !important"
        )

        v-data-table.mt-0.clientLogsTable(
          :headers="logsUsuarioHeaders",
          :items="fullItem.logs",
          dense,
          hide-default-footer
        )
      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="exibirLogs = false")
          span Fechar

  v-dialog(
    v-model="exibirDialogExclusao",
    v-if="exibirDialogExclusao",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Excluir
      v-card-text
        h5 Tem certeza que deseja excluir o registro?
      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="closeDialog")
          span Cancelar
        v-btn(color="error", outlined, @click="deleteItem")
          span Excluir

  v-dialog(
    v-model="exibirDialogClonar",
    v-if="exibirDialogClonar",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Clonar
      v-card-text
        h5.ma-0.pa-0.pt-2 Tem certeza que deseja clonar o registro?
        h5.ma-0.pa-0.pb-2 Após clonar, você será redirecionado para a tela de edição.
      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="closeDialog")
          span Cancelar
        v-btn(color="success", outlined, @click="cloneItem")
          span Clonar
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { socket, state } from "@/socket";

export default {
  data() {
    return {
      isDeveloper: false,
      filters: {
        includeSearch: true,
        items: [],
        searchText: "",
        useSearchHash: true,
      },
      filtersClientes: { includeSearch: true, items: [] },
      dataset: [],
      datasetItem: {},
      tentativasMenu: false,
      tentativasComandos: 1,
      dataHeaders: [
        {
          text: "ID",
          value: "id",
          width: "70",
          sortable: true,
          class: "pl-3 pr-0",
          cellClass: "pl-3 pr-0",
        },
        {
          text: "Nome",
          value: "nome",
          sortable: true,
          class: "pl-0 pr-3",
          cellClass: "pl-0 pr-3",
        },
        {
          text: "Status",
          value: "status",
          width: "80",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Execução única?",
          value: "execucao_unica",
          width: "170",
          sortable: true,
          align: "center",
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Intervalo",
          value: "tipo_intervalo",
          width: "100",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Inicia em",
          value: "datahora_inicio",
          width: "150",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Termina em",
          value: "datahora_fim",
          width: "150",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "",
          value: "data-table-expand",
          align: "center",
          width: "40",
          sortable: false,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
      ],
      logsUsuarioHeaders: [
        { text: "Tipo", value: "tipo", sortable: false },
        { text: "Data", value: "datahora", sortable: true },
        { text: "Mensagem", value: "mensagem", sortable: false },
      ],
      clientesLogsHeaders: [
        {
          text: "ID",
          value: "id",
          width: "70",
          align: "start",
          sortable: true,
          class: "pl-2 pr-2",
          cellClass: "pl-2 pr-2",
        },
        {
          text: "Cliente",
          value: "nomebaseself",
          align: "start",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Hash",
          value: "hash",
          width: "340",
          align: "start",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Status CS Server",
          value: "statusserver",
          width: "110",
          align: "center",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Última Data/Hora",
          value: "datahora",
          width: "200",
          sortable: true,
          class: "pl-2 pr-2",
          cellClass: "pl-2 pr-2",
        },
        {
          text: "Última Mensagem",
          value: "mensagem",
          align: "start",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "60",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "",
          value: "data-table-expand",
          align: "center",
          width: "60",
          sortable: false,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
      ],
      datasetClientesLogs: [],
      logsSeleccionados: "-",
      logsSucesso: "-",
      clientesisLoading: false,
      clientesLogsInfoHeaders: [
        { text: "Data/Hora", value: "datahora", width: "240", sortable: false },
        { text: "Tipo", value: "tipo", width: "138", sortable: false },
        { text: "Mensagem", value: "mensagem", sortable: false },
      ],
      fullItem: {},
      exibirLogs: false,
      exibirDialogExclusao: false,
      exibirDialogClonar: false,
      footerProps: {
        itemsPerPageOptions: [50, 100, 300, 500, -1],
        itemsPerPageAllText: "Todos",
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: "Itens por página",
      },
      footerPropsLogs: {
        itemsPerPageOptions: [10, 20, 50, 100, 300, 500, -1],
        itemsPerPageAllText: "Todos",
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: "Itens por página",
      },
    };
  },
  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    permissoesAcesso() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const role = roles.filter((role) => role.projectid == 257);

      if (role.length == 0) {
        this.$router.push(`/dashboard`);
      } else {
        const permissao = role.filter((permission) => permission.id == 4);
        this.isDeveloper = permissao.length > 0;
        if (this.isDeveloper) {
          this.dataHeaders[this.dataHeaders.length - 1].width = "300";
        } else {
          this.dataHeaders[this.dataHeaders.length - 1].width = "60";
        }
      }
    },

    linkCadastro(id, text) {
      window.location.hash = text;
      this.$router.push(`/datahub/cadastro/${id}`);
    },

    linkVisualizacao(id, text) {
      window.location.hash = text;
      this.$router.push(`/datahub/visualizacao/${id}`);
    },

    linkResultado(id, text) {
      window.location.hash = text;
      this.$router.push(`/datahub/${id}/views`);
    },

    async cloneToProduction(item) {
      this.showLoader("Carregando dados...");
      try {
        const { status, data } = await csapi.get(
          `/v1/datahub/${item.id}?type=clone`
        );

        if (status !== 200) {
          throw new Error("Erro ao buscar logs");
        }

        const { id, logs, clientes, sqls, ...rest } = data;

        const result = JSON.stringify({ sqls: sqls, ...rest });

        if ((await this.copyToClipboard(result)) === false) {
          return;
        }

        this.$toast.info("Consulta disponivel em sua área de tranferência");
      } catch (error) {
        this.$toast.error(error.message);
        console.error(error);
      } finally {
        this.getData();
        this.hideLoader();
      }
    },

    async importToProduction() {
      try {
        const item = await navigator.clipboard.readText();

        const data = JSON.parse(atob(atob(item)));
        const { sqls, ...rest } = data;

        if (process.env.NODE_ENV == "production") {
          sqls.forEach((sql) => {
            if (sql.versao_minima.endsWith(".0")) {
              sql.versao_minima = "";
            }
          });
        }

        // processo de gravação
        const { data: res } = await csapi.post("/v1/datahub", rest);
        for (const sql of sqls) {
          await csapi.post(`/v1/datahub/${res.id}/sqls`, {
            sql: sql.sql,
            versao_minima: sql.versao_minima,
          });
        }

        this.$toast.success("Cenário importado com sucesso");
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao importar cenário via ambientes");
      } finally {
        this.getData();
      }
    },

    toLocalDate(date) {
      const data = date.toString().split("T");
      return `${data[0].split("-").reverse().join("/")} ${
        data[1].split(".")[0]
      }`;
    },

    closeDialog() {
      this.exibirDialogExclusao = false;
      this.exibirDialogClonar = false;
      this.datasetItem = {};
    },

    async getData() {
      this.showLoader();
      try {
        const result = await csapi.get("/v1/datahub");
        this.dataset = result.data;

        this.setFilters();
      } catch (error) {
        this.$toast.error("Erro ao buscar dados");
        console.error(error);
      } finally {
        this.hideLoader();
        if (this.datasetItem.id != null) {
          // this.getClientesLogs(this.datasetItem);
        }
      }
    },

    async validaSendToProcesso(id) {
      const item = this.dataset.find((item) => item.id === id);

      // buscar no servidor os sql deste item
      let sqls = [];
      try {
        const result = await csapi.get(`/v1/datahub/${id}/sqls`);
        sqls = result.data;
      } catch (error) {
        this.$toast.error(
          "Envio Processamento: Problemas ao executar a validação dos SQLs"
        );
        return false;
      }

      // buscar no servidor os clientes deste item
      let clientes = [];
      try {
        const result = await csapi.get(
          `/v1/datahub/${id}/clientes?onlyhashs=true`
        );
        clientes = result.data;
      } catch (error) {
        this.$toast.error(
          "Envio Processamento:  Problemas ao executar a validação dos clientes"
        );
        return false;
      }

      let dataAtual = new Date();
      let dataFim = new Date(item.datahora_fim);

      if (dataFim <= dataAtual) {
        this.$toast.error(
          "Envio Processamento: Data final de execução deve ser maior que a data atual"
        );
        return false;
      }

      if (!item.modelo.fields || item.modelo.fields.length === 0) {
        this.$toast.error("Envio Processamento: Modelo de dados não informado");
        return false;
      }

      if (!item.modelo.fields.some((field) => field.key)) {
        this.$toast.error(
          "Envio Processamento: Modelo de dados não possui chave informada"
        );
        return false;
      }

      if (sqls.length == 0) {
        this.$toast.error(
          "Envio Processamento: Necessário informar ao menos um SQL"
        );
        return false;
      }

      const sqlsVazios = sqls.filter((item) => !item.sql);
      if (sqlsVazios.length > 0) {
        this.$toast.error(
          "Envio Processamento: Existem SQLs não informados que impedem a execução"
        );
        return false;
      }

      const sqlsVersaoVazia = sqls.filter((item) => item.versao_minima == "");
      if (sqlsVersaoVazia.length > 0) {
        this.$toast.error(
          "Envio Processamento: Existem SQLs com versão mínima não informada"
        );
        return false;
      }

      if (clientes.length == 0) {
        this.$toast.error(
          "Envio Processamento: Necessário informar ao menos um cliente"
        );
        return false;
      }

      return true;
    },

    setFilters() {
      this.filters.items = [];
      try {
        let status = this.dataset.map((item) => item.status);
        status = [...new Set(status)];
        status = status.map((item) => {
          const text =
            item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          return { field: "status", text: text, value: item };
        });

        this.filters.items.push({
          text: "Status",
          type: "select",
          values: status,
        });

        let execucaoUnica = this.dataset.map((item) => item.execucao_unica);
        execucaoUnica = [...new Set(execucaoUnica)];
        execucaoUnica = execucaoUnica.map((item) => {
          const text = item ? "Sim" : "Não";
          return { field: "execucao_unica", text: text, value: item };
        });

        this.filters.items.push({
          text: "Execução unica?",
          type: "select",
          values: execucaoUnica,
        });
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao criar os filtros");
      }
    },

    async getLogs(item) {
      this.datasetItem = item;

      this.showLoader();
      try {
        const result = await csapi.get(`/v1/datahub/${item.id}?type=info`);

        if (result.status !== 200) {
          this.$toast.error("Erro ao buscar logs");
          return;
        }

        result.data.logs = result.data.logs.reverse();
        result.data.logs.forEach((log) => {
          log.datahora = new Date(log.datahora).toLocaleString();
        });

        this.fullItem = result.data;
      } catch (error) {
        this.$toast.error("Erro ao buscar logs");
        console.error(error);
      } finally {
        this.hideLoader();
        this.exibirLogs = true;
      }
    },

    async getClientesLogs(item) {
      if (item.id == null) {
        return;
      }

      this.datasetClientesLogs = [];
      this.logsSeleccionados = "-";
      this.logsSucesso = "-";
      this.clientesisLoading = true;
      this.datasetItem = item;

      try {
        const result = await csapi.get(`/v1/datahub/${item.id}?type=full`);

        if (result.status.toString().startsWith("2") === false) {
          this.$toast.error("Erro ao buscar logs de execução");
          return;
        }

        result.data.clientes.forEach((cliente) => {
          cliente.id = cliente.cliente.id;
          cliente.hash = cliente.cliente.hash;
          cliente.menu = false;
          cliente.nomebaseself =
            cliente.cliente.nomebaseself ?? cliente.cliente.nome;
          cliente.statusserver = state.socketConnections.some(
            (row) => cliente.cliente.hash === row.data.token.hash
          );

          const lastLog = cliente.logs[cliente.logs.length - 1] || {};
          cliente.datahora = lastLog.datahora
            ? new Date(lastLog.datahora).toLocaleString()
            : "";
          cliente.mensagem = lastLog.mensagem || "";
          cliente.logs = cliente.logs.reverse();

          delete cliente.cliente;
        });

        this.datasetClientesLogs = result.data.clientes;
        this.logsSeleccionados = result.data.clientes.length;
        this.logsSucesso = result.data.clientes.reduce(
          (acc, item) =>
            acc +
            item.logs.reduce(
              (acc, t) => (t.mensagem.search("TEMPO") > -1 ? acc + 1 : acc),
              0
            ),
          0
        );
      } catch (error) {
        this.$toast.error("Erro ao buscar logs de execução");
        console.error(error);
      } finally {
        this.clientesisLoading = false;
      }
    },

    async sendToProcess(id) {
      this.showLoader();

      if (!(await this.validaSendToProcesso(id))) {
        this.hideLoader();
        return;
      }

      this.showLoader();
      try {
        await csapi.post(`/v1/datahub/${id}/processar`);
        this.$toast.success("Enviado para processamento");
      } catch (error) {
        this.$toast.error("Erro ao enviar para processamento");
        console.error(error);
      } finally {
        this.getData();
        this.hideLoader();
      }
    },

    async sendToStop(id) {
      this.showLoader();
      try {
        await csapi.post(`/v1/datahub/${id}/finalizar`);
        this.$toast.success("Processamento finalizado");
      } catch (error) {
        this.$toast.error("Erro ao finalizar processamento");
        console.error(error);
      } finally {
        this.getData();
        this.hideLoader();
      }
    },

    async deleteItem() {
      this.showLoader("Excluindo registro...");
      try {
        await csapi.delete(`/v1/datahub/${this.datasetItem.id}`);
        this.$toast.success("Registro excluído com sucesso");
        this.getData();
      } catch (error) {
        this.$toast.error("Erro ao excluir registro");
        console.error(error);
      } finally {
        this.getData();
        this.hideLoader();
        this.datasetItem = {};
        this.exibirDialogExclusao = false;
      }
    },

    async cloneItem() {
      this.showLoader("Clona registro...");
      try {
        const res = await csapi.post(
          `/v1/datahub/${this.datasetItem.id}/clone`
        );

        if (res.status.toString().startsWith("2")) {
          this.$toast.success("Registro clonado com sucesso");
          this.$router.push(`/datahub/cadastro/${res.data.id}`);
        } else {
          this.$toast.error("Erro ao clonar registro");
        }
      } catch (error) {
        this.$toast.error("Erro ao clonar registro");
        console.error(error);
      } finally {
        this.getData();
        this.hideLoader();
        this.datasetItem = {};
        this.exibirDialogClonar = false;
      }
    },

    async getReenviarComando(item) {
      if (
        isNaN(parseInt(this.tentativasComandos)) ||
        (parseInt(this.tentativasComandos) > 3 &&
          parseInt(this.tentativasComandos) < 1)
      ) {
        this.$toast.error("Número de tentativas deve ser entre 1 e 3");
        return;
      }

      item.menu = false;

      const link = `/v1/datahub/${this.datasetItem.id}/processar/cliente`;
      const dados = {
        cliente_id: item.cliente_id,
        retry: parseInt(this.tentativasComandos),
      };

      // this.showLoader("Reenviando comando...");
      try {
        const data = await csapi.post(link, dados);

        switch (data.status) {
          case 200:
            this.$toast.success(
              "Comando reenviado com sucesso para registro id: " +
                item.id +
                " e cliente id: " +
                item.cliente_id
            );
            break;
          case 202:
            this.$toast.success(
              "Comando reenviado com sucesso para registro id: " +
                item.id +
                " e cliente id: " +
                item.cliente_id
            );
            break;
          case 400:
            this.$toast.error("Status diferente de FINALIZADO");
            break;
          case 404:
            this.$toast.error("Cliente informado não consta na consulta");
            break;
          case 500:
            this.$toast.error("Erro ao reenviar comando");
            break;
          default:
        }
      } catch (error) {
        this.$toast.error("Erro ao reenviar comando");
        console.error(error);
      } finally {
        // setTimeout(() => {
        this.testativasComandos = 1;
        // this.hideLoader();
        // this.getClientesLogs(this.datasetItem);
        // }, 1500);
      }
    },

    getIntervalName(interval) {
      switch (interval) {
        case "minutes":
          return "Minuto(s)";
        case "hours":
          return "Hora(s)";
        case "days":
          return "Dia(s)";
        case "weeks":
          return "Semana(s)";
        case "months":
          return "Mês(es)";
        default:
          return "Não definido";
      }
    },

    async copyToClipboard(text) {
      try {
        const encodedText = btoa(btoa(text));
        await navigator.clipboard.writeText(encodedText);
        return true;
      } catch (error) {
        if (document.hasFocus()) {
          this.$toast.error("Erro ao copiar para a área de transferência");
        } else {
          this.$toast.error("Erro ao copiar, foco deve ser mantido na página");
        }
        return false;
      }
    },
  },

  created() {
    this.permissoesAcesso();
  },

  computed: {
    socketConnections() {
      this.datasetClientesLogs.forEach((item) => {
        const index = state.socketConnections.findIndex(
          (row) => item.hash === row.data.token.hash
        );
        if (index != -1) {
          item.statusserver = true;
        } else {
          item.statusserver = false;
        }
      });

      return state.socketConnections;
    },
  },

  mounted() {
    this.isControl(true);
    this.setTitle("DataHub");
    this.getData();

    const hash = window.location.hash;
    if (hash) {
      this.filters.searchText = decodeURIComponent(hash.split("#")[1]);
    }

    socket.on("controlsoft:datahub:browser", (row) => {
      if (row.data.token && this.datasetClientesLogs.length > 0) {
        const item = this.datasetClientesLogs.find(
          (item) => item.hash === row.data.token.hash
        );
        if (item) item.statusserver = true;
      }
    });
  },

  beforeDestroy() {
    socket.off("controlsoft:datahub:browser");
  },
};
</script>

<style>
.registrosTable .v-data-table__wrapper {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.clientesLogsTable .v-data-table__wrapper {
  height: auto;
  overflow-y: auto;
}
</style>